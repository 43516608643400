.barra-pendencia {
  position: relative;
}
.barra-pendencia button {
  min-width: 100px;
}

.barra-pendencia label {
  margin: 0 0 0px 0px;
}
.animation-pendencia-right {
  position: absolute;
  background-color: $cor2;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  color: $branco;
  opacity: 1;
  -webkit-transition: width 1s, visibility 2s;
  transition: width 1s, visibility 2s;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.animation-pendencia-right div {
  opacity: 1;
  -webkit-transition: opacity 0.7s;
  transition: opacity 0.7s;
}
.animation-pendencia-right.recolher {
  width: 30px;
  pointer-events: none;
  visibility: hidden;
}
.animation-pendencia-right.recolher div {
  opacity: 0;
}

.animation-pendencia-right p,
.animation-pendencia-right h1,
.animation-pendencia-right h2,
.animation-pendencia-right h5 {
  color: $branco;
}

.move-in {
  animation-name: moveIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
.icone-relatorio {
  background-color: $cor7;
  color: $branco;
  word-wrap: break-word;
  max-width: 200px;
  text-align: center;
}
.icone-pendencia {
  background-color: $cor2;
}

.icone-pendencia img {
  width: 30px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.icone-pendencia-left {
  background-color: $cor2;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.icone-pendencia-left img {
  width: 30px;
  margin-right: 0px;
  margin-bottom: 0px;
}
h2.branco,
p.branco {
  color: $branco;
}

@media (max-width: 575px) {
  .animation-pendencia-right .info-texto {
    text-align: center;
  }
  .icone-pendencia-left {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.icone-relatorio-custom {
  background-color: $cor7;
  color: $branco;
  word-wrap: break-word;
  max-width: 50px;
  text-align: center;
}
