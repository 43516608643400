body {
  color: $preto;
}

h1 {
  font-size: 20px !important;
  font-family: 'Roboto Slab', sans-serif;
}

h2 {
  font-size: 16px !important;
  font-family: 'Roboto Slab', serif;
}

p {
  font-size: 12px;
  font-family: 'Muli', sans-serif;
}

p,
h1,
h2,
h5 {
  color: $preto;
}
.label {
  color: #000000;
  font-size: 12px;
  padding-left: 7px;
  display: inline-block;
}
label {
  font-size: 9px;
  font-family: 'Muli', sans-serif;
  margin: 0 0 4px 0px;
}
.font-10px {
  font-size: 10px;
}

.font-12px {
  font-size: 12px;
}

label.font-10px {
  font-size: 10px;
  color: $cinza5;
}

p.italic {
  font-style: italic;
  font-family: 'Muli', sans-serif;
}

p.nunito {
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: $preto;
}

// .font-italic {
//   font-style: italic;
//   font-family: 'Muli', sans-serif;
// }

hr {
  margin-bottom: 0px;
}

// Toast
.icon_error,
.icon_sucess,
.icon_pendente_WHITE,
.icon_warning,
.icon_pendencia,
.icon_info,
.icon_fechar {
  max-width: 20px;
}

.toast-sasc {
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  left: 50%;
  margin-left: -254px;
  width: 509px;
  height: 50px;
  border-radius: 4px;
  margin-top: 9px;
}

.bg-cor1 {
  background-color: $cor1;
  color: $branco;
}
.bg-cor2 {
  background-color: $cor2;
  color: $branco;
}
.bg-cor3 {
  background-color: $cor3;
  color: $branco;
}
.bg-cor4 {
  background-color: $cor4;
  color: $branco;
}
.bg-cor5 {
  background-color: $cor5;
  color: $branco;
}
.bg-cor6 {
  background-color: $cor6;
  color: $branco;
}
.bg-branco {
  background-color: $branco;
}
.bg-cinza {
  background-color: $cinza1;
}
.bg-cinza2 {
  background-color: $cinza2;
}
.tag {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: $border-radius;
  background-color: $branco;
  margin: 3px;
  display: inline-block;
}

.form-view .tag {
  border: 1px solid $cinza3;
}

.card-header:first-child {
  border-radius: 0px;
}
.accordion {
  border-top: 2px solid $branco;
}
.accordion:last-child {
  margin-bottom: 50px;
}
.accordion.open {
  border-top: 2px solid $cor1;
  margin: 1px 0 0;
}

.accordion > .card .card-header {
  background-color: $branco;
  cursor: pointer;
}
.accordion > .card {
  overflow: inherit;
}
.barra-topo {
  margin-bottom: 20px;
  min-height: 130px;
}
.barra-topo.bg-cinza {
  background-color: $cinza1;
}

.container-com-topo {
  margin-top: 70px;
}

.form-control::placeholder {
  color: $cinza4;
  opacity: 1;
  font-weight: normal;
}
.section-form-confirm .form-control::placeholder {
  text-align: center;
}
.form-control.placeholder-active::placeholder {
  color: $preto;
  opacity: 1;
}

//removido arrow inputs number, a pedido do Designer Thiago Angelito
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:-ms-input-placeholder {
  color: $cinza4;
}

::-ms-input-placeholder {
  color: $cinza4;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus,
.btn-secondary:focus,
.btn-secondary.focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-light {
  color: $preto;
  min-width: 50px;
  // border: 1px solid $cinza2 !important;
}
.Select.com-icone .btn-input {
  color: $cinza4;
  border: none;
}

.btn.btn-pequeno {
  min-width: 80px;
}
.btn-input:hover {
  color: $cinza5;
}
.btn-light:hover {
  background-color: $branco;
  color: inherit;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type='text']:focus,
input[type='email']:focus,
input[type='number']:focus,
[type='text'].form-control:focus,
[type='email'].form-control:focus,
[type='tel'].form-control:focus,
[contenteditable].form-control:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $preto;
  background-color: $branco;
  border-color: $cinza3;
}
input[type='password']:focus,
[type='password'].form-control:focus {
  border-color: $cinza2;
}

.input-group-text {
  background-color: $branco;
  border: 1px solid $cinza2;
  border-radius: $border-radius;
}

.input-group > .form-control {
  border-right: 0px;
}
// CUSTOM SELECT
.defaultSelect {
  padding: 0px;
  background-color: $branco;
  min-height: 40px;
  font-family: 'Muli', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: $preto;
  border-radius: 10px;
  border: 1px solid $cinza2;
}

.Select.form-control.com-icone {
  padding-left: 30px;
}

.Select.form-control .Select-control {
  border: none;
  background-color: transparent;
  text-align: left;
}
.Select.form-control .Select-control:hover {
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0);
}

.defaultSelect .react-select__control {
  min-height: 40px;
  font-family: 'Muli', sans-serif;
  border-radius: 10px;
  border: none;
  border-bottom: none;
  background-color: $branco;
}
.defaultSelect .react-select__indicator-separator {
  width: 0px;
}
.Select .Select-control .Select-value-label {
  font-size: 12px;
}
.text-center .Select .react-select__value-container {
  display: flex !important;
  justify-content: center !important;
  margin-left: 30px;
}

.Select.form-control.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
}
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  color: $preto;
}
.Select .Select-menu-outer,
.react-select__menu {
  font-family: 'Muli', sans-serif;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: $branco;
  border: none !important;
  box-shadow: 0 0 20px #00000030 !important;
  margin-top: 0px;
  z-index: 300000 !important;
}

.Select .Select-placeholder,
.Select.Select--single > .Select-control .Select-value,
.react-select__placeholder {
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  color: $cinza4 !important;
  border-radius: 10px;
}

.Select.min-width-180 {
  min-width: 180px;
}
.Select.min-width-150 {
  min-width: 150px;
}

.Select.min-width-90 {
  min-width: 90px;
}

.Select.min-width {
  min-width: 250px;
}

.width-lote-add {
  width: 100%;
}

.input-pendencia label  {
  font-weight: bolder;
  font-size: 15px;
  color:red;
}
.input-pendencia input  {
  border-color: red;
}
.input-pendencia .Select {
  min-width: 250px;
}

.defaultSelect .react-select__option--is-selected {
  background-color: $cor4 !important;
  color: $branco;
}

.defaultSelect .react-select__option--is-focused {
  background-color: $cinza2;
}
.defaultSelect .react-select__option--is-acitive {
  background-color: $cinza2;
}

.defaultSelect .react-select__multi-value__remove:hover {
  background-color: transparent;
  color: $preto;
  cursor: pointer;
}
.css-1alnv5e {
  content: url('./img/icon_fechar_RED.svg');
  width: 15px;
  float: left;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.react-select__menu .control__indicator {
  position: absolute;
  top: 14px;
  left: 10px;
}

.defaultSelect
  .react-select__control.react-select__control--menu-is-open.react-select__control--menu-is-focused:hover {
  border-color: $cinza3;
}
.defaultSelect .react-select__control.react-select__control--is-focused {
  box-shadow: none;
}

.textArea {
  min-height: 60px;
}

.defaultSelect .css-1pahdxg-control:hover {
  border-color: $cinza3;
  box-shadow: none;
}
.defaultSelect .react-select__option {
  padding: 14px 12px;
  cursor: pointer;
}

.sasc-select .react-select__multi-value .react-select__multi-value__label {
  font-size: 100%;
}

.status-txt {
  font-size: 16px;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.font-bold {
  font-weight: bold;
}

.extra-bold {
  font-weight: 900;
}

// Avatar
.avatarCustom {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: $branco;
  background-color: $cinza5;
  font-size: 47px;
  margin: auto;
  z-index: 1;
}

.avatarCustom.simples {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $cinza5;
  width: 100px;
  height: 100px;
  font-size: 47px;
  margin: auto;
  z-index: 1;
  color: $preto;
  background-color: $cinza2;
}

.avatarCustom.no-background {
  background-color: transparent;
}

.avatarDatatable {
  width: 34px;
  height: 34px;
}

.avatarPerfil_Protocolo {
  background-color: $cor2;
}
.avatarPerfil_Auditor {
  background-color: $cor1;
}
.avatarPerfil_Consultorio {
  background-color: $cor3;
}
.avatarPerfil_Cooperado {
  background-color: $cor4;
}
.avatarPerfil_Admin {
  background-color: $cor5;
}

.icon-input {
  position: absolute;
  width: 15px;
  top: 14px;
  left: 15px;
}

.react-select__option {
  border-bottom: 1px solid $cinza2;
  font-size: 12px;
  //height: 50px;
  //padding: 15px;
  background-color: $branco;
}
.Select .Select-menu-outer {
  margin-top: 2px;
  margin-left: 3px;
}
.is-open .selectMenuItem {
  background-color: $branco;
}

.sasc-select .react-select__multi-value {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: $branco;
  border: 1px solid $cinza3;
  margin: 5px;
}

.sasc-select .react-select__multi-value span {
  color: $preto;
}

// FIM CUSTOM SELECT

.form-control {
  font-family: 'Muli', sans-serif;
  font-weight: bold;
  border: 1px solid $cinza2;
  border-radius: 10px;
  font-size: 14px;
  height: 42px;
  color: $preto;
}

.form-control::placeholder {
  color: $cinza4;
  opacity: 1;
  font-weight: normal;
}
.form-control.placeholder-active::placeholder {
  color: $preto;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $cinza1;
}

button,
.btn {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 13px;
  border-radius: 10px;
  border: none;
  min-width: 110px;
  max-width: 230px;
  padding: 0 15px 0 12px;
  line-height: 15px;
  min-height: 30px;
}

.btn.btn-sm {
  padding: 5px;
  font-size: 12px;
  min-width: 95px;
  min-height: 18px;
  line-height: 18px;
}

.btn-link {
  color: $preto;
  text-decoration: none;
  min-width: 50px;
  padding: 0px 15px;
  text-align: left;
}
  .btn-link:hover {
    color: $preto;
    text-decoration: none;
  }
  .btn-link:focus,
  .btn-link.focus {
    text-decoration: none;
  }

.btn-icon-only span {
  margin-bottom: -3px;
  width: 16px;
}
.btn-link span,
.btn-light span,
.btn-primary span,
.btn-secondary span {
  margin-right: 6px;
  margin-bottom: -3px;
  width: 16px;
}
// ICONES
span.icon-btn-notificacao {
  content: url('./img/icon_notificar.svg');
}

span.icon-btn-lotes {
  content: url('./img/icon_lotes.svg');
}


span.icon-btn-filtro-branco {
  content: url('./img/icon_filterWhite_SMALL.svg');
}

span.icon-btn-filtro {
  content: url('./img/icon_filter_SMALL.svg');
}


span.icon-btn-pesquisar {
  content: url('./img/icon_search.svg');
}


span.icon-btn-add {
  content: url('./img/icon_novo.svg');
}
span.icon-btn-wpp {
  content: url('./img/icon_wpp.svg');
}
span.icon-btn-editar {
  content: url('./img/icon_editar.svg');
}
span.icon-btn-editar-white {
  content: url('./img/icon_editar-white.svg');
}
span.icon-btn-fechar {
  content: url('./img/icon_fechar_WHITE.svg');
}

span.icon-btn-cancelar {
  content: url('./img/icon_fechar_BLACK.svg');
}

span.icon-btn-fechar-filtro-red {
  content: url('./img/icon_fechar_RED.svg');
}


span.icon-btn-fechar-filtro {
  content: url('./img/icon_fechar_BLACK.svg');
}

span.icon-btn-add-branco {
  content: url('./img/icon_novo_WHITE.svg');
}

span.icon-btn-ecxel {
  content: url('./img/icon_excel.svg');
}


span.icon-ocultar {
  content: url('./img/icon_ocultar.svg');
}

span.icon-visualizar {
  content: url('./img/icon_visualizar.svg');
}
span.icon-refresh {
  content: url('./img/icon_refresh.svg');
  width: 14px;
}


span.icon-btn-anexar {
  content: url('./img/icon_anexar.svg');
}

span.icon-btn-encaminhar {
  content: url('./img/icon_encaminhar_SMALL.svg');
}


span.icon-btn-check {
  content: url('./img/icon_check.svg');
}
span.icon-btn-pendente{
  content: url('./img/icon_pendente_BLACK.svg');
  width: 16px;
}
span.icon-btn-check-black {
  content: url('./img/icon_check_BLACK.svg');
}

span.icon-btn-excluir {
  content: url('./img/icon_excluir.svg');
}

span.icon-auxiliares{
  content: url(./img/convert-arrow.png);
  transform: rotate(90deg);
  cursor: pointer;
  width: 16px;
  height: 16px;
}

span.icon-btn-pendencia {
  content: url('./img/icon_pendente_SMALL.svg');
}

span.icon-btn-historico {
  content: url('./img/icon_history_SMALL.svg');
}

span.icon-btn-imprimir {
  content: url('./img/icon_imprimir.svg');
}
span.icon-btn-separar {
  content: url('./img/icon-separar.png');
}
span.icon-seta-baixo {
  content: url('./img/icon_ordenacao.svg');
  height: 17px;
}

span.icon-traco {
  content: url('./img/icon_colapse_open.svg');
  height: 17px;
}

span.icon-order {
  content: url('./img/icon_drag_drop.svg');
  height: 17px;
}

span.icon-btn-filtro-bar {
  content: url('./img/icon_filtros.svg');
  height: 20px;
  margin-right: 5px;
  margin-left: -30px;
  margin-bottom: -3px;
}
span.icon-visualizacao {
  content: url('./img/icon_visualizar.svg');
  height: 24px;
  margin-bottom: -5px;
}


span.icon-copy {
  content: url('./img/copy-solid.svg');
}

.icon-duvida{
  content: url('./img/Info_icon.svg');
  width: 18px;
  margin-left: 6px;
  cursor: pointer;
}

.icon-exclamation{
  content: url('./img/exclamation-mark.svg');
  width: 20px;
  //margin-left: 6px;
  cursor: pointer;
}

.icon-danger{
  content: url('./img/iconAlert_Atencao.svg');
  width: 30px;
  margin-left: 6px;
  cursor: pointer;
}
.icon-info{
  content: url('./img/information.svg');
  width: 23px;
  margin-left: 6px;
  cursor: pointer;
}
.info-tip.danger .tooltip-inner{
  background-color: $vermelho;
  max-width: 300px;
}
.info-tip.danger .arrow::before {
  border-top-color: $vermelho;
}

.iconMedio {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 4px;
}
/////////

.toastr p{
  color:white;
}
.btn-danger{
  color:white;
}
button.react-datepicker__navigation {
  border-radius: 0px;
  min-width: 0;
  max-width: 0;
  padding: 0;
  line-height: 0px;
  min-height: 10px;
}
.w-10 {
  max-width: 10% !important;
}
.btn-primary:disabled {
  background-color: $cinza2 !important;
  color: $cinza4 !important;
  opacity: 1 !important;
}

.input-group-append .button,
.input-group-append .btn {
  font-weight: 700;
  font-size: 13px;
  min-width: 48px;
  height: 38px;
  top: 2px;
  right: 1px;
  padding: 0px 10px;
  background-color: $branco;
  // border: 1px solid $cinza2;
  border-left: none;
  position: absolute;
}

// .input-group-append.atendimentosLote {
//   position: absolute;
//   right: 45px;
//   top: 0;
//   padding: 0px 5px;
// }

.mt-25 {
  margin-top: 25px;
}
.btn-acoes > button.btn-mais:last-child,
.btn-acoes > button.btn-menos:last-child {
  margin-bottom: 8px;
}

button.btn-mais {
  background: url('./img/icon_mais.svg') no-repeat;
  height: 20px;
  width: 20px;
  display: flex;
  min-width: inherit;
  min-height: inherit;
}

button.btn-menos {
  background: url('./img/icon_menos.svg') no-repeat;
  height: 20px;
  width: 20px;
  display: flex;
  min-width: inherit;
  min-height: inherit;
  margin-bottom: 2px;
}

button.btn-excluir {
  background: url('./img/icon_excluir.svg') no-repeat;
  height: 20px;
  width: 20px;
  display: flex;
  min-width: inherit;
  min-height: inherit;
  margin-bottom: 2px;
}

footer {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: -10;
}

input {
  font-family: 'Muli', sans-serif;
  font-size: 12px;
  border: 1px solid $cinza3;
  border-radius: 10px;
}

// .menu {
//   position: fixed;
//   z-index: 100;
//   width: 100%;
//   box-shadow: 4px 0px 20px #0000001c;
// }

// ANIMATIONS
.fade-in {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out {
  opacity: 1;
  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-in {
  animation-name: moveIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  // transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}
@keyframes moveIn {
  0% {
    transform: translate(0, -150%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.move-out {
  animation-name: moveOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  // transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}
@keyframes moveOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -150%);
  }
}

.move-up {
  animation-name: moveUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
@keyframes moveUp {
  0% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.move-down {
  animation-name: moveDown;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}
@keyframes moveDown {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -20px);
    opacity: 0;
  }
}

/////////////////////////////////////

h2.muli {
  font-family: 'Muli', sans-serif;
}

.border-right.grande {
  height: 63px;
}

.background-branco {
  background: $branco;
  margin-bottom: 1px;
}

.background-branco.h-70 {
  height: 70px !important;
}

.background-branco.h-50 {
  height: 50px !important;
}

.background-branco.h-30 {
  height: 30px !important;
}
.b-dash {
  border: 1px dashed $cinza4;
}
/* Layouts */
.background-lines {
  position: relative;
}
.bg-riscos {
  background-color: $cinza2;
  background: url('./Background-menu.svg') no-repeat;
}

.background-lines .bg-riscos {
  position: absolute;
  height: 545px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  background-color: none;
}



.bg-Sudo {
  position: absolute;
  height: 1000px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  background-color: none;
}
.bg-riscos.bottom {
  bottom: 0;
  right: 0;
  top: initial;
  left: initial;
  height: 400px;
  width: 600px;
  transform: rotate(180deg);
  position: absolute;
}

.background-guia {
  -webkit-background-size: 45%;
  -moz-background-size: 45%;
  -o-background-size: 45%;
  background-size: 45%;
}

.box-branco {
  transition: all 1s ease-in-out;
  margin: auto;
  background: $branco;
  border-radius: 60px;
  width: 424px;
  height: 444px;
  z-index: 2;
}

.box-branco.grande {
  border-radius: 10px;
}

.box-branco-pequeno {
  position: fixed;
  margin-left: calc(50% - 115px);
  background: $branco;
  width: 200px;
  height: 80px;
  border-radius: 10px;
  bottom: 0;
  margin-bottom: 10px;
}

.box-branco-atendimentos {
  margin-left: calc(50% - 935px);
  background: $cinza1;
  width: 270px;
  height: 80px;
  border-radius: 10px;
}

.box-branco-repassados {
  background: $cinza1;
  border-radius: 10px;
  padding: 20px;
}

.box-header {
  margin: auto;
  background: $branco;
  border-radius: 10px;
  height: auto;
  margin-bottom: -5%;
}

.mb-15-negative {
  margin-bottom: -15px;
}

button.verde {
  background: $cor4;
  width: 130px;
}

button.verde.menor {
  width: 90px;
}

button.azul-marinho {
  background: $cor1;
  width: 220px;
}
.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.mt-6,
.my-6 {
  margin-top: 5rem !important;
}
.verde-destaque {
  color: $cor4 !important;
}
.verde-super-destaque {
  color: $cor5 !important;
}

.cor-destaque-alerta {
  color: $vermelho !important;
}

button.branco {
  color: $preto !important;
  background: $branco;
  width: 130px;
  border-radius: 10px;
  border: none;
}

button.branco.grande {
  width: 177px;
}

.borda {
  padding-bottom: 5%;
  border-block-end: 1px solid $cinza4;
  max-width: 630px !important;
}

.borda-larga {
  border-block-end: 2px solid $cor1;
}

.borda-input-anexo {
  border: 2px solid $cor7;
  border-radius: 5px;
}

.borda-input-anexo-coopmed {
  border: 5px solid $cor7;
  border-radius: 5px;
  background-color: rgba(43, 69, 78, 0.5);
}

.btn-avancar img {
  width: 10px;
  height: 20px;
}

.btn-icon-only {
  min-width: 0px !important;
  padding: 0px !important;
  min-height: 0px !important;
}
.popover {
  max-width: 320px;
}
.cut-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 //max-width: 60%;
  margin: 0;
}

.fixed-container-topo {
  position: fixed;
  top: calc(90px - 1.5rem);
  z-index: 1000;
  left: 15px;
  width: calc(100% - 30px);
  background-color: transparent;
}
.contentComTopoFix {
  margin-top: 220px;
}
.contentComTopoFixLote {
  margin-top: 310px;
}
.wc-75 {
  width: 75px;
}

.button-add {
  margin-top: 1.8rem !important;
}

.border-top {
  border-top: 1px solid #eff3f4 !important;
}

.w-260 {
  width: 260px;
}
.popover-anexos span.icon-btn-excluir {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: -10px;
}

.cardCrud span.icon-btn-excluir {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 8px;
}

.muli {
  font-family: 'Muli', sans-serif;
}
.nunito {
  font-family: 'Nunito', sans-serif;
}
.roboto {
  font-family: 'Roboto Slab', sans-serif;
}

.action {
  cursor: pointer;
}
.actionTopo {
  min-width: 100px;
}
.lineFile {
  cursor: pointer;
}
.lineFile div {
  min-height: 25px;
  padding: 0px 5px;
}
.modalCenter {
  display: flex;
  align-items: center;
}
.modal-content {
  border: none;
  box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 424px;
  margin: auto;
}
.modal-lg .modal-content {
  width:100%;
  overflow-y: scroll;
  max-height: 500px;
}
.lg .modal-body{
  width: 700px;
}
.cinza .modal-content {
  background-color: $cinza1;
}

.modalLoading .modal-content {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
  box-shadow: none;
}

.disabled {
  opacity: 0.2;
}
.bodyNoScroll {
  overflow-y: hidden !important;
}

.helpText {
  color: $cor3;
  font-size: 12px;
}

.is-invalid {
  border: 2px solid $vermelho;
}
.cardCrud .section-form div.form-group input.is-invalid {
  border: none;
  border-bottom: 2px solid $vermelho !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: center right 5px;
  background-size: 15px;
}
.section-form .input-required input.form-control.is-invalid {
  border: 2px solid $vermelho;
}

.input-required textarea {
  border-color: $cinza5;
}

.checkbox-com-borda {
  background-color: $cinza3;
  border-radius: 10px;
}

.react-confirm-alert .form-group label {
  color: $preto;
  font-size: 12px;
}

// CUSTOM ALERT
.confirm-ui {
  text-align: center;
  width: 325px;
  padding: 30px 20px 0px;
  background: $branco;
  border-radius: 10px;
}

.confirm-ui.confirm-ui-form {
  width: 380px;
}

.confirm-ui > p b {
  color: $cor2;
  font-weight: bold;
}

.confirm-ui.success > h2 {
  color: $preto;
  margin-bottom: 0px;
}

.confirm-ui > p {
  color: $preto;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 6px;
}
.confirm-ui .body-confirm {
  color: $preto;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 6px;
  font-size: 12px;
  font-family: 'Muli', sans-serif;
}

.confirm-ui-nova {
  width: 700px;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
}

.css-alert-sasc img {
  width: 55px;
  margin-bottom: 10px;
}
.react-confirm-alert .css-alert-sasc h2 {
  font-weight: bold;
  margin-bottom: 0px;
}

// CUSTOM BOTÕES ALERT
.confirm-ui .btn-secondary,
.cancel-ui .btn-secondary {
  background: $cor1;
}

.confirm-ui button,
.cancel-ui button {
  font-size: 12px;
  border: none;
  min-width: 90px;
  max-width: 110px;
  padding: 0px 20px;
  min-height: 30px;
}

.confirm-ui.confirm-ui-gray {
  background-color: $cinza1;
  padding: 30px 15px 0px;
}

.cardCrud .section-form.section-form-confirm {
  padding: 5px 0px;
}

//////////////////////

.cardCrud.card {
  //border-top: 2px solid $cor2;
  //border-radius: 0;
  border-left: none;
  border-right: none;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  padding-left: 10px;
  padding-right: 10px;
}

.invalid-tip .tooltip-inner {
  background-color: $danger;
}

.invalid-tip .arrow::before {
  border-top-color: $danger;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: $danger;
}

.cardCrud .cardInconsistencia {
  padding: 0.45rem 1.25rem 0.45rem;
  margin-bottom: 0;
  background-color: $branco;
  color: $danger;
}

.cardInconsistencia .spanMensagem {
  color: $success;
}

.cardCrud .card-header {
  padding: 1.75rem 1.25rem 1.75rem 0.75rem;
  margin-bottom: 0;
  background-color: $branco;
  border-bottom: none;
  height: 70px;
  color: $preto;
}



.cardCrud .card-body {
  padding: 0 0.25rem 0 0.25rem;
}

.cardCrud .subheader.card-header {
  padding: 0.45rem 1.25rem 0.75rem;
  margin-bottom: 0;
  background-color: $cinza1;
  border-bottom: none;
  color: $cinza5;
  height: 30px;
  font-size: 10px;
}

.form-group label {
  color: $preto;
  font-size: 12px;
  margin-bottom: -5px;
  padding-left: 10px;
}
.form-group.text-center label {
  padding-left: 0px;
}

// .form-group.form-group-boorder:first-child {
//   border-left: 1px solid $cinza2;
// }
// .form-group.form-group-boorder {
//   margin-bottom: 1px;
//   border-right: 1px solid $cinza2;
//   border-bottom: 1px solid $cinza2;
// }

// .form-group.form-group-boorder:last-child {
//   border-right: none;
//   border-bottom: 1px solid $cinza2;
// }

.pl-15 {
  padding-left: 15px;
}

.txtGreen {
  color: $cor4;
}
.txtGreenDark {
  color: $cor2;
}
.txtGreenDark1 {
  color: $cor1;
}

.txtRed {
  color: $vermelho;
}
.fz-sm-10 {
  font-size: 10px;
}
.fz-sm-11 {
  font-size: 11px;
}

.fz-sm-12 {
  font-size: 12px;
}

.fz-sm-15 {
  font-size: 15px;
}

.fz-sm-16 {
  font-size: 16px !important;
}

.modal-footer.customModalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
}

.Select .avatarCustom {
  width: 32px;
  height: 32px;
  font-size: 16px;
  margin: -10px auto;
}

.Select .customOption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
  margin: 0;
}

.highlight {
  color: $cor4;
}

///////////////////////FORMULÁRIOS E GUIAS//////////////////
.section-form {
  color: $cinza5;
  font-size: 10px;
  font-style: italic;
  padding: 0px 15px;
}

.card.content-form {
  border: none;
  background: transparent;
}
.section-form .convenios .control--checkbox span {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: $preto;
  font-style: normal;
}
.section-form > h6 {
  padding-left: 15px;
  font-size: 10px;
  line-height: 30px;
  margin-bottom: 0px;
  height: 30px !important;
  background-color: $cinza1;
}
.section-form > .convenios label.desabilitado {
  font-size: 12px;
  font-style: normal;
  padding: 0px 10px;
}

.visualizacao .section-form > div.form-group {
  border-right: 1px solid $cinza2;
}
.visualizacao .section-form > div.b-bottom {
  border-bottom: 1px solid $cinza2;
}
.visualizacao .section-form > div.form-group > p {
  line-height: 20px;
  margin-bottom: 3px;
}

.form-group.b-bottom {
  margin-bottom: 0px;
}

.section-form > div.form-group.text-center .form-control {
  text-align: center;
}

.section-form > div.form-group,
.section-form > div.form-row div.form-group {
  padding-right: 5px;
  padding-left: 5px;
  background-color: $branco;
  padding-bottom: 3px;
  margin-bottom: 0px;
}

.section-form > div .form-control:focus {
  box-shadow: 0 0 0px $cor3 !important;
  outline-offset: 0px !important;
  outline: none !important;
  border: none;
}

.section-form > div > label {
  margin: 0px;
  padding: 0px 10px;
}
.section-form > div.form-group .form-control {
  width: 100%;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid $cinza4;
  height: 50px;
  padding: 0px 10px;
}
.section-form > div.form-group .form-control:disabled,
.section-form > div.form-group .form-control[readonly] {
  color: rgb(153, 153, 153);
  opacity: 1;
  border: none;
  background-color: $branco;
}

.status-guia {
  font-size: 16px;
  font-family: 'Roboto Slab', sans-serif;
  font-weight: lighter;
}
.roboto-light {
  font-size: 16px;
  font-family: 'Roboto Slab', sans-serif;
  font-weight: lighter;
}
.h-20 {
  height: 20px;
  background-color: $branco;
}

.big-badge {
  font-family: 'Roboto Slab', serif;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  font-size: 46px;
  background-color: $cor7;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: $branco;
}
// .section-form > div.form-group .form-control.destaque {
//   border-color: $cinza5;
// }
.section-form > div.form-group .form-control.is-invalid {
  border-bottom: 2px solid $vermelho;
}
.form-inside .section-form > div.form-group .form-control.is-invalid {
  border-bottom: 2px solid $vermelho;
}

// .section-form .no-border-right {
//   border-right: none;
// }
// .section-form > div.form-group:last-child {
//   border-right: none;
// }

.section-form > div.form-group > p,
.section-form p.form-view {
  font-size: 14px;

  font-style: normal;
  padding: 0px 10px;
  margin: 0px;
  line-height: 20px;
}
p.form-view .txtGray {
  color: $cinza5;
}

.cardCrud .section-form .input-required input {
  border-color: $preto;
  border-width: 2px;
  color: $preto;
}
.cardCrud .section-form {
  padding: 0px 14px;
}
.cardCrud .input-required label {
  font-weight: bold;
  margin-bottom: -5px;
}

.cardCrud .section-form .input-required .react-select__control {
  border-color: $preto;
  border-width: 2px;
}

.cardCrud .section-form .input-required .is-invalid .react-select__control {
  border-bottom: 2px solid $vermelho !important;
}
.cardCrud .defaultSelect.is-invalid .react-select__control{
  border-bottom: 2px solid $vermelho !important;
}

.cardCrud .input-required label {
  font-weight: bold;
}

.cardCrud .section-form > div.form-group,
.cardCrud .section-form > div.form-row > div.form-group {
  padding: 7px 5px;
}
.card.cardGuia {
  border-radius: 0px;
}

.section-form > div.form-group textarea.form-control {
  height: 120px;
}
.section-form-confirm.section-form > div.form-group textarea.form-control {
  height: 40px;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0px !important;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.input-required .css-tlfecz-indicatorContainer {
  color: $preto;
}

////////NOVO FORM///////

// BOX
.form-inside .section-form div.form-group {
  padding: 0px;
  background-color: #ffffff;
  min-width: 140px;
  padding: 20px;
  flex: 1 1 auto !important;
  width:0;
}

.form-inside .section-form div.form-group.central {
  max-width: 140px;
  min-width: 70px;
  padding: 20px 5px;
  text-align: center;
}

.form-inside
  .section-form
  div.form-group.central
  .Select
  .react-select__value-container {
  display: flex !important;
  justify-content: center !important;
  // margin-left: 30px;
}

.form-inside .section-form div.form-group.central.fixo {
  width: 70px;
}
.form-inside .section-form .quebra {
  flex-basis: 100%;
  height: 0;
  // border: 3px solid $cinza2;
}

.form-inside .section-form div.form-group.fixo {
  width: 210px;
  flex: 0 0 auto !important;
}

.form-inside .section-form div.form-group.fixo2 {
  width: 250px;
  flex: 0 0 auto !important;
}

.form-inside .section-form div.form-group.fixo3 {
  width: 110px !important;
  flex: 1 1 auto !important;
}

.form-inside .section-form div.form-group.fixo4 {
  width: 250px;
  flex: 1 1 auto !important;
}

.form-inside .section-form div.form-group.fixo5 {
  width: 90px;
  padding: 20px 7px;
}

.form-inside .section-form div.form-group.fixo6 {
  width: 60px;
  padding: 20px 5px;
}

.form-inside .section-form div.form-group.fixo7 {
  width: 200px;
  padding: 20px 5px;
}

.form-inside .section-form div.form-group.fixo8 {
  width: 60px;
}

.form-inside .section-form div.form-group.large {
  width: 30%;
}
.form-inside .section-form div.form-group.full {
  width: 80%;
}

.form-inside .section-form .form-group:first-child {
  border-left: 1px solid $cinza2;
  border-top: 1px solid $cinza2;
}
.form-inside .section-form .form-group {
  border-right: 1px solid $cinza2;
  border-bottom: 1px solid $cinza2;
  border-top: 1px solid $cinza2;
}

.form-inside .section-form > .w-100 + div {
  border-left: 1px solid $cinza2;
}

.form-inside .section-form > .quebra + div {
  border-left: 1px solid $cinza2;
}

// .form-inside .section-form > .quebra ~ div {
//   border-top: 0px solid $cinza2;
// }
.form-inside .section-form .form-group.campoPendente {
  outline: 2px solid #3c6669;
  outline-offset: -2px;
  z-index: 2;
  background-image: url('./img/icon_pendente_SMALL.svg');
  background-size: 10px;
  background-position: 97% center;
  background-repeat: no-repeat;
  background-color: rgba(60, 102, 105, 0.37);
  font-weight: bolder;
}
.form-inside .section-form .icone-pendente {
  display: none;
}
.form-inside .section-form .form-group.campoPendente .icone-pendente {
  display: inline-flex;
  font-size: 30px;
}

.form-inside .section-form div.form-group.w-20 {
  width: 15%;
}

.form-inside .section-form div.form-group.w-15 {
  max-width: 30% !important;
}

// INPUTS
// LABELS
.form-inside .section-form .label-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0;
}
.form-inside .section-form .input-required label {
  font-weight: bold;
}
.form-inside .section-form label {
  color: $preto;
  font-size: 12px;
  padding-left: 10px;
  display: inline-block;
}

.text-preto{
  color:$preto
}

.form-inside .section-form .form-group.central label {
  padding-left: 0px;
  padding-right: 0px;
}

// INPUT COMUM
.form-inside .section-form > div.form-group .form-control {
  width: 100%;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #cccccc;
  height: 35px;
  padding: 0px 10px;
}

.form-inside .section-form > div.form-group .input-group .form-control {
  width: 90%;
}

.form-inside .section-form > div.form-group .input-group .input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.form-inside .section-form > div.form-group textarea.form-control {
  min-height: 60px;
}
.form-inside .section-form > div.form-group.central .form-control {
  text-align: center;
}

// REQUIRED
.form-inside .section-form div.form-group .input-required input {
  border-color: #000000;
  border-width: 2px;
  color: #000000;
}

// DISABLED
.form-inside .section-form div.form-group .form-control:disabled,
.form-inside .section-form div.form-group .form-control[readonly] {
  color: $cinza5;
  font-weight: bold;
  background-color: $branco;
  border-bottom: 1px solid $cinza4;
}

// SELECT
.form-inside .defaultSelect {
  padding: 0px;
  background-color: $branco;
  min-height: 30px;
  font-family: 'Muli', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: $preto;
  border-radius: 10px;
  border: initial;
}

.form-inside .defaultSelect .react-select__control {
  min-height: 30px;
  font-family: 'Muli', sans-serif;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid $cinza4;
  background-color: $branco;
  cursor: pointer;
}

.form-inside .Select .Select-placeholder,
.form-inside .Select.Select--single > .Select-control .Select-value,
.form-inside .react-select__placeholder {
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  color: $cinza4 !important;
  border-radius: 10px;
}
.form-inside .css-tlfecz-indicatorContainer,
.form-inside .css-1gtu0rj-indicatorContainer {
  padding: 0px !important;
  width: 13px;
  height: 20px;
  margin-right: 10px;
}

.form-inside .icon-input {
  position: absolute;
  width: 15px;
  top: 12px;
  left: 20px;
}
////////////////////// FIM FORMULÁRIOS E GUIAS//////////////

.f-16 {
  font-size: 16px;
}

.f-15 {
  font-size: 15px;
}
.f-14 {
  font-size: 14px;
}
.f-13 {
  font-size: 13px;
}
.f-12 {
  font-size: 12px;
}
.f-11 {
  font-size: 11px;
}

.w-250p {
  width: 250px;
}

.W-10p {
  max-width: 22%;
}

.icon-duvida:before {
  content: "\e914";
}
.icon-danger:before {
  content: "\e914";
}
.w-25 {
  width: 25%;
}
.w-40 {
  width: 40%;
}
.w-60px {
  width: 60px;
  max-width: 60px;
}

.icon-atentimento {
  height: 40px;
}

.cinza1 {
  color: $cinza1;
}
.cinza2 {
  color: $cinza2;
}
.cinza3 {
  color: $cinza3;
}
.cinza4 {
  color: $cinza4;
}

.cinza5 {
  color: $cinza5;
}

.acerto-p-m {
  padding: 0px 15px;
  margin: 0px 10px;
}
.form-inside .section-form div.form-group.w-18 {
  width: 10%;
}
.badge {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  position: absolute;
  right: 25px;
  display: inline-block;
  width: 20px;
  height: 16px;
  padding: 2px;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
}
.badge-error {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  position: absolute;
  right: 8em;
  top: 21px;
  display: inline-block;
  width: 20px;
  height: 16px;
  padding: 2px;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  padding-bottom: 15px;
}

.badge-danger {
  background-color: $vermelho;
  border: 1px solid $vermelho1;
}
.react-datepicker-popper {
  z-index: 10;
}
.sentencaEncontrada {
  outline: 2px solid $cor4 !important;
  outline-offset: -2px;
}

.info-user h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 265px;
}
.width-col-fixed {
  width: 100px;
}

.width-100 {
  width: 100%;
}

.flexGrow2 {
  flex-grow: 2;
}

.form-row-1 {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: -1px;
}
.topo-guia.bg-branco {
  background: $branco;
  box-shadow: 0px 6px 4px #00000005;
  border-radius: 0px 0px 10px 10px;
}
// #guia {
//   min-height: 768px;
// }
@media (max-width: 1023px) {
  // FORM INSIDE
  .form-inside .section-form div.form-group {
    min-width: 100px;
    width: 100%;
  }

  .form-inside .section-form div.form-group.central {
    max-width: 50%;
    width: 50%;
    padding: 20px 20px;
    text-align: left;
  }

  .form-inside .section-form div.form-group.central.fixo {
    width: 50%;
  }

  .form-inside .section-form div.form-group.fixo {
    width: 50%;
  }

  .form-inside .section-form .quebra {
    display: none;
  }
  .form-inside .section-form div.form-group.mobile-100 {
    width: 100%;
    min-width: 100%;
  }
}

/* MEDIA MOBILE */

@media (max-width: 575px) {
  .contentComTopoFix {
    margin-top: 320px;
  }

  .mobile-100 {
    width: 100% !important;
  }
  .big-badge {
    height: 40px;
    width: 40px;
    font-size: 20px;
  }
  .box-branco {
    margin-top: 20%;
  }
  .box-branco-pequeno {
    margin-bottom: -10px;
  }

  .box-branco-atendimentos {
    margin-bottom: -60px;
  }

  .box-branco-repassados {
    border-radius: 0;
    padding: 0;
  }

  .border-bootom {
    border-bottom: 1px solid $cinza4;
    opacity: 1;
  }

  .border-left {
    border-left: none !important;
  }

  button.btn-mais {
    position: initial;
  }

  .btn-novo-atend {
    position: relative;
    bottom: -75px;
    top: 50%;
  }

  .borda-separacao {
    border-bottom: 1px solid #eff3f4;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-right: none;
  }

  .btn.btn-small-mobile {
    min-width: 20px;
  }
  .toast-sasc {
    position: fixed;
    z-index: 99999999;
    padding: 0 5px;
    left: 0%;
    margin-left: auto;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-top: 9px;
  }

  .box-branco {
    margin-top: 5%;
  }
  .box-branco-pequeno {
    margin-bottom: -10px;
  }

  .toast-sasc {
    position: fixed;
    z-index: 99999999;
    padding: 0 5px;
    left: 0%;
    margin-left: auto;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-top: 9px;
  }

  .toast-success {
    background: $primary;
  }

  .toast-error {
    background: $danger;
  }

  .toast-warning {
    background: $warning;
  }

  .toast-pendencia {
    background: $cor2;
  }

  .toast-error {
    background: $danger;
  }

  .toast-warning {
    background: $warning;
  }

  .toast-pendencia {
    background: $cor2;
  }

  .container-com-topo {
    margin-top: 90px;
  }

  // CUSTOM ALERT
  .react-confirm-alert button,
  .btn {
    margin-right: 10px;
    margin-left: 10px;
  }

  .input-group-append .button,
  .input-group-append .btn {
    margin: 0;
  }
  .w-60px {
    width: inherit;
  }

  footer {
    position: initial;
    bottom: 0;
    text-align: center;
    margin-top: 14px;
  }
  .bg-riscos.bottom {
    background: none;
  }
}

@media (max-width: 320px) {
  .box-branco {
    height: 386px;
  }
}

.txt-Red-Bold {
  font-weight: bolder;
  font-size: 13px;
  color:red;
}

.w-10-custom {
  max-width: 15% !important;
}

.direita {
  float: right;
}

.accordion2 {
  border-top: 2px solid $branco;
}

.accordion2.open {
  border-top: 2px solid $cor1;
  margin: 1px 0 0;
}

.anexoSimNao{
  width: 100% !important;
  margin-left: 5% !important;
}

.anexoFixo{
  width: 30% !important;
}

.anexoObservacaoConsulta{
  width: 70% !important;
}

.anexoObservacaoHonorario{
  width: 10% !important;
}

.anexoHonorario{
  width: 0% !important;
}

.anexoObservacaoInternacao{
  width: 200px !important;
}

.anexoSimNaoInternacao{
  width: 100% !important;
  margin-left: 0% !important;
}

.dataEmissaoInternacao{
  width: 0% !important;
}

.modalExames {
  min-width: 424px;
}

.notificationCenter {
  width: 60%;
  position: relative;
  margin-top: 2.3rem !important;
  left: 12%;
}
.modal-anexos {
  max-width: 500px;
}

.modal-lg-anexos .modal-content {
  width:100%;
  max-height: 500px;
}

.itemLista {
  margin-bottom: 10px;
}

.modal-xl .modal-content {
  width:100%;
  height: 500px !important;
  overflow-y: auto;
  max-height: 500px;
}

.visualizar-anexo{
  height: 290px !important;
  max-height: calc(100% - 200px);
  overflow-y: auto;
}

.link-estilizado {
  color: rgb(0, 0, 0);
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s;
}

.link-estilizado:hover {
  color: rgb(255, 255, 255);
}

.h2-alert {
  font-size: 16px !important;
  font-family: 'Roboto Slab', serif;
  color: white;
}

.div-form-anexo{
  max-width: 350px;
}

