.menu-historico {
  width: 400px;
  height: 100%;
  position: fixed;
  right: -400px;
  top: 0;
  opacity: 0;
  background-color: $cinza2;
  z-index: 1049;
  transition-property: opacity, right;
  transition-timing-function: linear, linear;
  transition-duration: 0.5s, 0.5s;
  box-shadow: 9px 0px 20px 0px #ccc;
  overflow-y: auto;
}
.menu-historico.aberto {
  right: 0px;
  opacity: 1;
  transition-property: opacity, right;
  transition-timing-function: linear, linear;
  transition-duration: 0.5s, 0.5s;
}
.item-historico:first-child {
  background-color: $cinza2;
  padding-top: 25px;
  margin-top: 0px;
}

.item-historico:last-child {
  margin-bottom: 0px;
  padding-bottom: 35px;
}

@media (max-width: 575px) {
  .menu-historico {
    width: 100%;
  }
}
