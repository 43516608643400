.control {
  position: relative;
  cursor: pointer;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0px;
  width: 15px;
  height: 15px;
  background: $branco;
  border: 1px solid $cinza4;
  border-radius: 3px;
  z-index: 10;
}

/* Hover and focus states */
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  // background: $cor5;
}

/* Checked state */
.control input:checked ~ .control__indicator {
  background: $cor4;
  border-color: $cor6;
}

/* Hover state whilst checked */
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: $cor4;
}

/* Disabled state */
.control input:disabled ~ .control__indicator {
  pointer-events: none;
  opacity: 0.6;
  background: #e6e6e6;
}

/* Check mark */
.control__indicator:after {
  position: absolute;
  display: none;
  content: "";
}

/* Show check mark */
.control input:checked ~ .control__indicator:after {
  display: block;
}
td .classChecked:parent {
  background-color: $cor1;
}

td .classChecked:parent {
  background-color: $cor1;
}
/* Checkbox tick */
.control--checkbox .control__indicator:after {
  top: 3px;
  left: 5px;
  width: 4px;
  height: 6px;
  transform: rotate(45deg);
  border: solid #fff;
  border-width: 0 2px 2px 0;
}

/* Disabled tick colour */
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
