.txtCooperado {
    text-align: center;
    max-width: 300px;
    margin: 10px auto 47px;
}

/* MEDIA MOBILE */

@media (max-width: 1024px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 575px) { 
}

@media (max-width: 414px) {
}

@media (max-width: 375px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
    .txtCooperado {
        margin: 10px auto 8px;
        max-width: 250px;
    }
}