.modalCooperado {
  max-width: 650px;
}
.btn-fechar img {
  width: 18px;
}
.iconeCooperados img {
  height: 60px;
}
.txtModal p {
  margin: auto 40px;
}

// /* MOBILE WIDTH */

// @media (max-width: 1024px) {
//   .over {
//     overflow-y: auto;
//     height: 304px;
//   }
// }

// @media (min-width: 768px) and (max-width: 991.98px) {
//   .over {
//     overflow-y: auto;
//     height: 304px;
//   }
// }

// @media (max-width: 575px) {
//   .topo {
//     position: fixed !important;
//   }
//   .box-branco.cooperado {
//     margin-top: 5%;
//     margin-bottom: 5%;
//   }
//   .over {
//     overflow-y: auto;
//     height: 304px;
//   }
//   .box-branco .header .btn-fechar img {
//     margin-top: 5%;
//   }
// }

// /* MOBILE HEIGHT */

// @media (max-height: 568px) {
//   .over {
//     height: 300px;
//   }
// }
// @media (min-height: 640px) and (max-height: 666px) {
//   .over {
//     height: 370px;
//   }
// }
// @media (min-height: 667px) and (max-height: 730px) {
//   .over {
//     height: 400px;
//   }
// }
// @media (min-height: 730px) and (max-height: 811px) {
//   .over {
//     height: 460px;
//   }
// }
// @media (min-height: 812px) and (max-height: 1000px) {
//   .over {
//     height: 464px;
//   }
// }
