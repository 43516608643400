.topo {
  position: fixed;
  height: 70px;
  top: 0;
  z-index: 1000;
  background-color: $cinza2;
  // opacity: 0.9;
  // backdrop-filter: blur(5px);
}

  .topo .iconNotification,
  .topo .iconMenu,
  .topo .iconLogo,
  .topo .iconError {
    height: 26px;
    cursor: pointer;
  }

  .topo.iconMenu .btnVoltar img,
  .topo.iconNotification .btnVoltar img,
  .topo .iconError{
    transform: rotate(90deg);
    cursor: pointer;
  }

.text-topo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
  margin: 0;
  position: absolute;
  left: 50%;
}
.topo .iconError {
  position: absolute;
  right: 100px;
  top: 24px;
}




// .iconNotificacao {
//   opacity: 0.15;
// }

