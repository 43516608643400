.btn-topo {
  position: fixed;
  text-align: center;
  bottom: 26px;
  left: 45%;
  padding: 5px 20px 10px;
  border-radius: 20px;
  background-color: $cinza2;
  color: $preto;
  // border: 1px solid $cinza3;
  box-shadow: 0 0 30px #00000030;
}

  .btn-topo img.top-button {
    height: 12px;
    transform: rotate(180deg);
  }

  .btn-topo span.title-button-top {
    display: block;
    font-size: 12px;
    font-weight: 700;
  }
