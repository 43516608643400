.menu-model-view {
  width: 400px;
  height: 100%;
  position: fixed;
  right: -400px;
  top: 0;
  background-color: $cinza2;
  z-index: 1049;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 9px 0px 20px 0px #ccc;
  overflow-y: auto;
}
.menu-model-view.aberto {
  right: 0px;
}
.menu-model-view .form-group {
  margin-bottom: 0px;
}

.h-40 {
  height: 40px;
}
.item-model-view:first-child {
  background-color: $cinza2;
  padding-top: 25px;
  margin-top: 0px;
}

.item-model-view:last-child {
  margin-bottom: 0px;
  padding-bottom: 35px;
}

#botao-criar{
  margin-top: 20px;
}

#botao-editar{
  position: absolute;
  float: right;
  margin-top: 20px;
}
#botao-cancelar{
  position: relative;
  left: 30%;
}
.btn-ok {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 575px) {
  .menu-model-view {
    width: 100%;
  }
}
