.col,
.col-1,
.col-8 {
  transition: all 0.2s ease-in-out;
}
.initial {
  flex: 0 0 60%;
  max-width: 60%;
  padding-right: 15px;
  padding-left: 15px;
}
.active {
  flex: 0 0 95%;
  max-width: 95%;
  padding-right: 15px;
  padding-left: 15px;
}
.inactive {
  flex: 0 0 23%;
  max-width: 23%;
  padding-right: 15px;
  padding-left: 15px;
}

.horizonScroll {
  flex-wrap: nowrap;
  left: 21%;
  position: relative;
  transition: all 0.2s ease-in-out;
}
// Posição ao arrastar
.horizonScroll.scroll01 {
  left: -43%;
}
.horizonScroll.scroll02 {
  left: -105%;
}
.horizonScroll.scroll03 {
  left: -168%;
}
.horizonScroll.scroll04 {
  left: -228%;
}
.horizonScroll.scroll05 {
  left: -290%;
}

.horizonScroll.scroll06 {
  left: -350%;
}

.horizonScroll.scroll07 {
  left: -415%;
}

.horizonScroll.scroll08 {
  left: -470%;
}

.horizonScroll.scroll09 {
  left: -530%;
}
.horizonScroll.scroll010{
  left: -600%;
}

// Ajuste de posição ao abrir
.horizonScroll.scroll0ON {
  left: 2%;
}
.horizonScroll.scroll1ON {
  left: -15%;
}
.horizonScroll.scroll2ON {
  left: -38%;
}
.horizonScroll.scroll3ON {
  left: -60%;
}
.horizonScroll.scroll4ON {
  left: -85%;
}
.horizonScroll.scroll5ON {
  left: -110%;
}
.horizonScroll.scroll6ON {
  left: -135%;
}
.horizonScroll.scroll7ON {
  left: -160%;
}
.horizonScroll.scroll8ON {
  left: -182%;
}

.horizonScroll.scroll9ON {
  left: -203%;
}

.horizonScroll.scroll10ON {
  left: -227%;
}

.conteudoComponente {
  height: 140px;
  align-items: center;
}

.colorOFF {
  opacity: 0.3;
  cursor: default;
}
.colorOFF h2 {
  color: $preto;
}
.colorOFF p {
  color: $preto;
  font-weight: 700;
}

.line {
  transition: all 0.4s ease-in-out;
  border-top: 1px solid $cinza4;
  max-width: 800px;
  width: 80%;
  margin: 20px 20%;
}

.naoFaturado {
  background-color: $cor0;
  cursor: pointer;
}
.iconeNaoFaturado {
  background: url("./img/icon_auditoria_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeNaoFaturadoOFF {
  background: url("./img/icon_auditoria_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaNaoFaturado {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor0;
}
.lineNaoFaturado {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor0;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}


.auditoria {
  background-color: $cor1;
  cursor: pointer;
}
.iconeAuditoria {
  background: url("./img/icon_auditoria_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeAuditoriaOFF {
  background: url("./img/icon_auditoria_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaAuditoria {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor1;
}
.lineAuditoria {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.pendente {
  background-color: $cor2;
  cursor: pointer;
}
.iconePendente {
  background: url("./img/icon_pendente_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconePendenteOFF {
  background: url("./img/icon_pendente_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaPendente {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor2;
}
.linePendente {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor2;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.pendenciaResolvida {
  background-color: $cor1-1;
  cursor: pointer;
}
.iconePendenciaResolvida {
  background: url("./img/icon_auditoria_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconePendenciaResolvidaOFF {
  background: url("./img/icon_auditoria_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaPendenciaResolvida {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor1-1;
}
.linePendenciaResolvida {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1-1;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.recebida {
  background-color: $cor1-2;
  cursor: pointer;
}
.iconeRecebida {
  background: url("./img/icon_auditoria_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeRecebidaOFF {
  background: url("./img/icon_auditoria_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaRecebida {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor1-2;
}
.lineRecebida {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1-2;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.guiaEletronica {
  background-color: $cor1-4;
  cursor: pointer;
}

.iconeGuiaEletronica {
  background: url("./img/icon_auditoria_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeGuiaEletronicaOFF {
  background: url("./img/icon_auditoria_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaGuiaEletronica {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor1-4;
}
.lineGuiaEletronica {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1-4;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.removidaLote{
  background-color: $cor1-3;
  cursor: pointer;
}
.iconeRemovidaLote {
  background: url("./img/icon_auditoria_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeRemovidaLoteOFF {
  background: url("./img/icon_auditoria_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaRemovidaLote {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor1-3;
}
.lineRemovidaLote {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor1-3;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.faturamento {
  background-color: $cor3;
  cursor: pointer;
}
.iconeFaturamento {
  background: url("./img/icon_faturado_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeFaturamentoOFF {
  background: url("./img/icon_faturado_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaFaturamento {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor3;
}
.lineFaturamento {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor3;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.glosado {
  background-color: $cor4;
  cursor: pointer;
}
.iconeGlosado {
  background: url("./img/icon_glosado_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeGlosadoOFF {
  background: url("./img/icon_glosado_BLACK.svg") no-repeat center;
  height: 40px;
}
.setaGlosado {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor3;
}
.lineGlosado {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor4;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.repassado {
  background-color: $cor5;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}
.iconeRepassado {
  background: url("./img/icon_repassado_WHITE.svg") no-repeat center;
  height: 40px;
}
.iconeRepassadoOFF {
  background: url("./img/icon_repassado_BLACK.svg") no-repeat center;
  height: 40px;
}
.iconeRepassadoGREEN {
  background: url("./img/icon_repassado_GREEN.svg") no-repeat center;
  height: 80px;
}
.setaRepassado {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1px;
  top: 30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $cor4;
}
.lineRepassado {
  transition: all 0.4s ease-in-out;
  border-top: 2px solid $cor5;
  max-width: 600px;
  width: 60%;
  margin: 20px 0;
}

.iconeFechar {
  background: url("./img/icon_fechar_WHITE.svg") no-repeat center;
  height: 16px;
  align-self: flex-start;
  margin-top: 20px;
}

.sombra {
  height: 26px;
  position: absolute;
  left: -20px;
  top: -20px;
  opacity: 0.1;
}

.ball_ON {
  width: 8px;
  height: 8px;
  background-color: $preto;
  border-radius: 8px;
  margin: 30px 5px 10px 5px;
}
.ball_OFF {
  width: 8px;
  height: 8px;
  border: 1px solid $preto;
  border-radius: 8px;
  margin: 30px 5px 10px 5px;
}

/* MOBILE */
@media (max-width: 1023px) {
  .componenteStatus {
    overflow: hidden;
    text-align: center;
    margin-left: -30px;
    margin-right: -30px;
  }
  // .componenteStatus h2 {
  //   font-weight: 700;
  //   color: $branco;
  //   margin: 0;
  // }
  // .componenteStatus p {
  //   color: $branco;
  //   margin: 0;
  // }
  .line {
    margin: 20px 10%;
  }
}
