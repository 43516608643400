.menu-filtro {
  width: 400px;
  height: 100%;
  position: fixed;
  right: -400px;
  top: 0;
  background-color: $cinza2;
  z-index: 1049;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 9px 0px 20px 12px #ccc;
  overflow-y: auto;
}
.menu-filtro .react-select__option {
  font-size: 12px;
  padding-bottom: 5px;
  border-bottom: none;
}

.menu-filtro.aberto {
  right: 0px;
}

.topo-notificar {
  text-align: center;
}
.topo-notificar h1 {
  display: inline-block;
}

.bg-color4 {
  background-color: $cor1;
}
.bg-color4 div {
  color: $branco;
}
.bg-color4 h2 {
  color: $branco;
  font-weight: bold;
  margin-block-end: 0px;
}

.menu-filtro .descricao {
  padding: 30px 67px;
}

.menu-filtro .quebra-linha {
  margin: 0px 17px;
  border-color: transparent;
  background-image: linear-gradient(to right, #cccccc 50%, transparent 50%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
.menu-filtro .label-checkbox {
  font-size: 12px;
  color: $preto;
  padding-left: 30px !important;
}
.menu-filtro .box-list-perfil {
  padding: 30px;
}
.menu-filtro .box-list-usuario {
  padding: 30px;
}
.menu-filtro .list-usuario-item {
  padding: 13px 20px;
}
.menu-filtro h5 {
  font-size: 9px;
}
.menu-filtro .list-usuarios {
  background-color: $branco;
}
.btn-add-filter {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.react-datepicker-popper {
  z-index: 1100 !important;
}
.btn-aplicar {
  position: relative;
  bottom: 0;
  left: 15%;
  transform: translate(-50%, -50%);
}
.menu-filtro .avatarCustom {
  width: 32px;
  height: 32px;
  font-size: 16px;
  margin: -10px auto;
}

@media (max-width: 575px) {
  .menu-filtro {
    width: 100%;
  }
}
